const PROFILE_ROOT = '/profile';
export default Object.freeze({
  ROOT: '',
  RECORD: '/record',
  SPEAK: '/speak',
  LISTEN: '/listen',
  USER_MSG: '/user-msg/:msg/:type(error|success)',
  PROFILE: PROFILE_ROOT,
  PROFILE_INFO: PROFILE_ROOT + '/info',
  AFFILIATE_PAGE: PROFILE_ROOT + '/affiliate',
  SENTENCES_IMPORT: '/sentences',
  TASK_CREATE: '/tasks',
  USERS: '/users',
  OTHER_SETTINGS_PAGE: '/other-settings',
  USER_SUSPEND_PAGE: '/user-suspend',
  ADMIN_STATS: '/admin-stats',
  CLIPS_STATS: '/clips-stats',
  EXPERT_STATS: '/expert-stats',
  VOTE_STATS: '/vote-stats',
  USER_STATS: '/user-stats',
  SENTENCE_STATS: '/sentence-stats',
  CATEGORY_STATS: '/category-stats',
  DASHBOARD: '/dashboard',
  DATA: '/data',
  DATASETS: '/datasets',
  FAQ: '/faq',
  PRIVACY: '/privacy',
  TERMS: '/terms',
  NOTFOUND: '/not-found',
  LANGUAGES: '/languages',
  INSTRUCTIONS: '/instructions',
});
