var dateTime = require('node-datetime');

/**
 * Datos formatavimas į aiškų formatą
 * 
 * @param {string} dateString
 * @returns {string} - YYYY-mm-dd
 */
export const formatDate = (dateString: string): string => {
  if (!dateString) {
    return null;
  }
  const date = new Date(dateString);
  const d = date.getDate();
  const m = date.getMonth() + 1;
  const yyyy = date.getFullYear();
  const dd = d < 10 ? '0' + d : d.toString();
  const mm = m < 10 ? '0' + m : m.toString();
  return yyyy + '-' + mm + '-' + dd;
};


  /**
   * sesijos kintamieji užsetinami į query parametrus
   */
  export const updateQueryParams = async (stateParams: any) => {
    const generatedQuery = "?" + new URLSearchParams(getNonNullApiParams(stateParams)).toString();
    const existingQuery = window.location.search;

    if (generatedQuery != existingQuery) {
      window.history.pushState(" ", " ", generatedQuery);
    }
  }

  const getNonNullApiParams = (stateParams: any):any => {
    let nonNullParams:any = {};
    for (const param in stateParams) {
      if (stateParams[param]) {
        if (stateParams[param] instanceof Date) {
          nonNullParams[param] = dateTime.create(stateParams[param]).format('Y-m-d');
        } else {
          nonNullParams[param] = stateParams[param].toString();
        }
      }
    }
    return nonNullParams;
  }

  export const convertToDateIfMatchesPattern = (param: string) => {
    if (param.match(/^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/)) {
      const d = dateTime.create(param).format('Y/m/d');
      return new Date(d);
    }
    return param;
  }