import * as React from 'react';
import { Localized, withLocalization } from 'fluent-react/compat';
import { trackGlobal } from '../../services/tracker';
import URLS from '../../urls';
import ContactModal from '../contact-modal/contact-modal';
import { LocaleLink } from '../locale-helpers';
import { ContactIcon, DiscourseIcon, SupportIcon } from '../ui/icons';
import { TextButton } from '../ui/ui';
import Logo from './logo';
import { User } from '../../stores/user';

import './footer.css';
import API from '../../services/api';
import { connect } from 'react-redux';
import StateTree from '../../stores/tree';

interface FooterState {
  showContactModal: boolean;
}

interface PropsFromState {
  api: API;
  user: User.State;
}

interface Props extends PropsFromState {}

class Footer extends React.PureComponent<Props, FooterState> {
  state: FooterState = {
    showContactModal: false,
  };

  private shareURLInput: HTMLInputElement;

  private toggleContactModal = () => {
    trackGlobal('contact');
    this.setState(state => ({ showContactModal: !state.showContactModal }));
  };

  render() {
    const { user } = this.props;
    return (
      <footer>
        {this.state.showContactModal && (
          <ContactModal onRequestClose={this.toggleContactModal} />
        )}
        <div id="help-links">

          <LocaleLink id="faq" to={URLS.FAQ}>
            <SupportIcon />
            <Localized id="faq">
              <div />
            </Localized>
          </LocaleLink>
          <div className="divider" />

          <LocaleLink id="help" to={URLS.INSTRUCTIONS}>
            <DiscourseIcon />
            <Localized id="expert-info">
              <div />
            </Localized>
          </LocaleLink>
          <div className="divider" />
          <TextButton onClick={this.toggleContactModal}>
            <ContactIcon />
            <Localized id="contact">
              <div />
            </Localized>
          </TextButton>
        </div>
        <div id="moz-links">
          <div className="logo-container">
            <Logo reverse />
            <p className="license">
              <Localized id="content-license-text">
                <span />
              </Localized>
            </p>
          </div>
          <div className="links">
            <div>
              <Localized id="terms">
                <LocaleLink to={URLS.TERMS} />
              </Localized>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default connect<PropsFromState>(({ user, api }: StateTree) => ({
  api,
  user,
}))(Footer);
