import { Localized } from 'fluent-react/compat';
import * as React from 'react';
import URLS from '../../urls';
import { ContributableLocaleLock, LocaleNavLink } from '../locale-helpers';

import './nav.css';
import { USER_PERMISSION_ADMIN } from '../../../../common/user-clients';

export default ({ children, user, ...props }: { [key: string]: any }) => (
  <nav {...props} className="nav-list">
    <div className="nav-links">
      {user && user.account && <Localized id="speak">
            <LocaleNavLink to={URLS.SPEAK} exact />
          </Localized>
      }
      {user && user.account && user.account.permissions !== 'user' && (
        <Localized id="listen">
          <LocaleNavLink to={URLS.LISTEN} />
        </Localized>
      )}
      {user && user.account && (
        <Localized id="profile">
          <LocaleNavLink to={URLS.PROFILE_INFO} exact />
        </Localized>
      )}
      {user && user.account && user.account.permissions == USER_PERMISSION_ADMIN && (
        <Localized id="admin-zone">
          <LocaleNavLink to={URLS.USERS} exact />
        </Localized>
      )}
    </div>
    {children}
  </nav>
);
