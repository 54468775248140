import { LanguageStats } from 'common/language-stats';
import {
  UserClient,
  UserWithDetailedVotesStats,
  BaseUser,
  UserWithContribution,
} from '../../../common/user-clients';
import { Locale } from '../stores/locale';
import { User } from '../stores/user';
import { USER_KEY } from '../stores/root';
import { Sentences } from '../stores/sentences';
import { RecordVariant } from '../../../common/RecordVariant';
import { Sentence, SentenceWithClipsData } from '../../../common/sentence';
import { CategoryInterface, Category, CategoryWithSentenceStats } from '../../../common/categoryInterface';
import { SettingsInterface, Setting } from '../../../common/settings';
import {
  AdminStatsInterface,
  AdminClipsStatsInterface,
  AdminExpertStatsInterface,
  AdminSentenceStatsInterface,
  AdminUserStatsInterface,
  AdminCategoryStatsInterface,
  AdminStatsVoteStatsInterface, AdminStatsSuspendedUserInterface,
} from '../../../common/admin-stats';
import { Expert } from '../../../common/expert';
import { AffiliateInfoInterface, ContributionPointsTabbleInterface } from '../../../common/AffiliateInfo';
import { ExpertInstruction } from '../../../common/ExpertInstruction';
import { ClipWithVoteStats } from '../../../common/clip';
import { ClipStatsFilterWithPagination } from '../../../common/clip-stats-filter';
import { ExpertStatsFilterWithPagination } from '../../../common/expert-stats-filter';
import { CaptchaClipsRequest } from '../../../common/captcha-clips';
import {
  UserStatsFilterWithPagination,
  UserStatsFilter,
} from '../../../common/user-stats-filter';
import {
  SentenceStatsFilter,
  SentenceStatsFilterWithPagination,
} from '../../../common/sentence-stats-filter';
import { savedTask, TaskWithExecution } from '../../../common/task';
import {CategoryStatsFilterWithPagination} from '../../../common/category-stats-filter';
import {VoteStatsFilter, VoteStatsFilterWithPagination} from 'common/vote-stats-filter';
import {DBVote} from 'common/vote';
import {formatDate} from '../components/pages/admin-stats/helpers';
import {UsersFilterWithPaginationInterface} from 'common/users-filter';
import {UserSuspendInterface, UserSuspendWithEmailInterface} from "../../../common/suspended-user";
import {UserSuspendFilter, UserSuspendFilterWithPagination} from "../../../common/user-suspend-filter";

export interface Clip {
  id: number;
  glob: string;
  text: string;
  sound: string;
  message: string;
}

interface FetchOptions {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  isJSON?: boolean;
  headers?: {
    [headerName: string]: string;
  };
  body?: any;
}

const API_PATH = location.origin + '/api/v1';

/**
 * @class API
 */
export default class API {
  private readonly locale: Locale.State;
  private readonly user: User.State;

  constructor(locale: Locale.State, user: User.State) {
    this.locale = locale;
    this.user = user;
  }

  private async fetch(path: string, options: FetchOptions = {}): Promise<any> {
    const { method, headers, body, isJSON } = Object.assign(
      { isJSON: true },
      options
    );

    const finalHeaders = Object.assign(
      isJSON
        ? {
            'Content-Type': 'application/json; charset=utf-8',
          }
        : {},
      headers
    );

    if (path.startsWith(location.origin) && !this.user.account) {
      finalHeaders.client_id = this.user.userId;
    }

    const response = await fetch(path, {
      method: method || 'GET',
      headers: finalHeaders,
      credentials: 'same-origin',
      body: body
        ? body instanceof Blob
          ? body
          : JSON.stringify(body)
        : undefined,
    });
    if (response.status == 401) {
      localStorage.removeItem(USER_KEY);
      location.reload();
      return;
    }
    if (response.status >= 400) {
      throw new Error(await response.text());
    }
    return isJSON ? response.json() : response.text();
  }

  forLocale(locale: string) {
    return new API(locale, this.user);
  }

  getLocalePath() {
    return this.locale ? API_PATH + '/' + this.locale : API_PATH;
  }

  getClipPath() {
    return this.getLocalePath() + '/clips';
  }

  getStatsPath() {
    return this.getLocalePath() + '/stats'
  }

  getExpertInfoPageClipStreamPaths(
    existingInstructionIds: number[]
  ): Promise<{ result: ExpertInstruction[]; error: string }> {
    return this.fetch(this.getClipPath() + '/expert-clip-info-stream-paths', {
      method: 'POST',
      body: existingInstructionIds,
    });
  }

  fetchRandomSentences(count: number = 1, cachedSentences: Sentences.Sentence[]): Promise<Sentences.Sentence[]> {
    return this.fetch(this.getLocalePath() + '/sentences', {
      method: "POST",
      body: {
        count,
        cachedSentences
      }
    });
  }

  fetchRandomClips(count: number = 1, cachedClips: number[]): Promise<Clip[]> {
    return this.fetch(this.getClipPath() + '/random_clips', {
      method: 'POST',
      body: {
        count,
        cachedClips
      }
    });
  }

  /**
   * Užklausa į backend laiškui siųsti
   * 
   * @param details 
   * @promise {{boolean} result, {string} error}
   */
  sendEmail(details: {
    email: string;
    subject: string;
    message: string;
    name: string;
  }): Promise<{ result: boolean; error: string }> {
    return this.fetch(API_PATH + '/send_email', {
      method: 'POST',
      body: details,
    });
  }

  /**
   * Užklausa, garso įrašui išsaugoti
   * 
   * @param blob 
   * @param sentenceId 
   * @param sentence 
   */
  uploadClip(blob: Blob, sentenceId: number, sentence: string): Promise<void> {
    return this.fetch(this.getClipPath(), {
      method: 'POST',
      headers: {
        'Content-Type': blob.type,
        sentence: encodeURIComponent(sentence),
        sentence_id: sentenceId.toString(),
      },
      body: blob,
    });
  }

  /**
   * Užklausa užduočiai sukurti
   * Administratoriaus sritis
   *  
   * @param {{ category: string; amount: number }[]} taskCategories 
   * @param {string} taskName 
   * @param {number} recordVariantId 
   * 
   * @promise {{string} result?, {string} error?}
   */
  saveTask(
    taskCategories: { category: string; amount: number }[],
    taskName: string,
    recordVariantId: number
  ): Promise<{result: boolean, error: string}> {
    return this.fetch(API_PATH + '/save_task', {
      method: 'POST',
      body: { taskCategories, taskName, recordVariantId },
    });
  }

  /**
   * Užklausa įrašo garso įrašo tinkamumo vertinimą
   * @param {number} id - garso įrašo id
   * @param {boolean} isValid - garso įrašo tinkamumas
   * 
   * @returns {Promise<{string} result, {string} error>} - garso įrašo išsaugojimo vieta
   */
  saveVote(id: number, isValid: boolean): Promise<{result: string, error: string}> {
    return this.fetch(`${this.getClipPath()}/${id}/votes`, {
      method: 'POST',
      body: { isValid },
    });
  }

  /**
   * Užklausa administratoriaus statistikai gauti
   * Administratoriaus sritis
   * 
   * @returns {Promise<{{AdminStatsInterface} result, {string} error>}}
   */
  fetchDashboardAdminStats(): Promise<{result: AdminStatsInterface, error: string}> {
    return this.fetch(API_PATH + '/admin_stats_for_dashboard');
  }


  /**
   * Užklausa garso įrašams gauti.
   * Administratoriaus sritis.
   * 
   * @param {ClipStatsFilterWithPagination} filter 
   * 
   * @returns Promise<{result: ClipWithVoteStats[], error: string}>
   */
  fetchDataForAdminClipStats(
    filter: ClipStatsFilterWithPagination
  ): Promise<{
    result: ClipWithVoteStats[];
    error: string;
  }> {
    return this.fetch(this.getStatsPath() + '/data_for_admin_clip_stats', {
      method: 'POST',
      body: this.modifyDateFromAndDateToForBackend(filter),
    });
  }

  /**
   * Garso įrašų statistikos užklasa
   * Administratoriaus sritis
   * 
   * @param {ClipStatsFilterWithPagination} filter
   * 
   * @returns Promise<{ result: AdminClipsStatsInterface, error: string }>
   */
  fetchStatsForAdminClipStats(filter: ClipStatsFilterWithPagination): Promise<{ result: AdminClipsStatsInterface, error: string }> {
    return this.fetch(this.getStatsPath() + '/stats_for_admin_clips_stats', {
      method: 'POST',
      body: this.modifyDateFromAndDateToForBackend(filter),
    });
  }

  /**
   * Užklausa kategorijai trinti
   * 
   * 
   * @param {number} id - kategorijos id
   * @returns Promise<{boolean} result, {string} error> 
   */
  deleteCategory(id: number) : Promise<{result: boolean, error: string}> {
    return this.fetch(this.getStatsPath() + '/delete_category', {
      method: 'POST',
      body: {id},
    });
  }

  /**
   * Eksportuotų garso įrašų užklausa
   * Administratoriaus sritis
   * 
   * @promise gaunamas eksportuotų garso įrašų sąrašas
   * @resolve {{string} name, {string} link}[]} result
   * @resolve {string} error
   */
  fetchExportedClips(): Promise<{
    result: { name: string; link: string }[], error: string
  }> {
    return this.fetch(this.getStatsPath() + '/exported_files_list');
  }

  /**
   * Eksportuoto garso įrašų failo trynimo užklausa
   * Administratoriaus sritis
   * 
   * @param name 
   * 
   * @returns Promise<{{boolean} result, {string} error}>
   */
  deleteExportedClip(
    name: string
  ): Promise<{ result: boolean, error: string }> {
    return this.fetch(this.getStatsPath() + '/delete_exported_file', {
      method: 'POST',
      body: {
        name,
      },
    });
  }

  /**
   * Ekspertų duomenų užklausa 
   * Administratoriaus sritis
   * @param {ExpertStatsFilterWithPagination} filter 
   * 
   * @returns Promise<{{Expert[]} result, {string} error}>
   */
  fetchDataForAdminExpertStats(
    filter: ExpertStatsFilterWithPagination
  ): Promise<{ result: Expert[], error: string }> {
    return this.fetch(this.getStatsPath() + '/data_for_admin_expert_stats', {
      method: 'POST',
      body: this.modifyDateFromAndDateToForBackend(filter),
    });
  }

  /**
   * Ekspertų statistikos užklausa
   * @param filter 
   * 
   * @returns Promise<{{AdminExpertStatsInterface} result, {string} error}>
   */
  fetchStatsForAdminExpertStats(
    filter: ExpertStatsFilterWithPagination
  ): Promise<{ result: AdminExpertStatsInterface, error: string }> {
    return this.fetch(this.getStatsPath() + '/stats_for_admin_expert_stats', {
      method: 'POST',
      body: this.modifyDateFromAndDateToForBackend(filter),
    });
  }

   /**
   * Gaunamas ekspertų vertinimo atitikties indeksas
   * 
   * @param {number} userId - vartotojo ID
   * @resolves {number} - grąžinamas skaitinis indeksas nuo -1 iki 1
   */
  fetchExpertVotingStat(userId: number): Promise<{result: number, error: string}> {
    return this.fetch(this.getStatsPath() + '/expert_vote_rating/' + userId);
  }

  /**
   * Diktorių duomenų užlausa
   * Administratoriaus sritis
   * @param {UserStatsFilterWithPagination} filter 
   * 
   * @returns Promise<{{UserWithDetailedVotesStats[]} result, {string} error}>
   */
  fetchDataForAdminUserStats(
    filter: UserStatsFilterWithPagination
  ): Promise<{ result: UserWithDetailedVotesStats[], error: string }> {
    return this.fetch(this.getStatsPath() + '/data_for_admin_user_stats', {
      method: 'POST',
      body: this.modifyDateFromAndDateToForBackend(filter),
    });
  }
  /**
   * Suspenduotų vartotojų duomenų užlausa
   * Administratoriaus sritis
   * @param {UserSuspendFilterWithPagination} filter
   *
   * @returns Promise<{{UserSuspendWithUserInterface[]} result, {string} error}>
   */
  fetchDataForSuspendedUserStats(
    filter: UserSuspendFilterWithPagination
  ): Promise<{ result: UserSuspendWithEmailInterface[], error: string }> {
    return this.fetch(this.getStatsPath() + '/data_for_suspended_user_stats', {
      method: 'POST',
      body: this.modifyDateFromAndDateToForBackend(filter),
    });
  }
  /**
   * Suspenduotų vartotojų duomenų statistika
   * Administratoriaus sritis
   * @param {AdminStatsSuspendedUserInterface} filter
   *
   * @returns Promise<{{AdminStatsSuspendedUserInterface[]} result, {string} error}>
   */
  fetchStatsForSuspendedUserStats(
    filter: UserSuspendFilter
  ): Promise<{ result: AdminStatsSuspendedUserInterface, error: string }> {
    return this.fetch(this.getStatsPath() + '/stats_for_suspended_user_stats', {
      method: 'POST',
      body: this.modifyDateFromAndDateToForBackend(filter),
    });
  }

  /**
   * Diktorių statistikos užklausa
   * Administratoriaus sritis
   * @param {UserStatsFilter} filter 
   * 
   * @returns Promise<{{AdminUserStatsInterface} result, {string} error}>
   */
  fetchStatsForAdminUserStats(
    filter: UserStatsFilter
  ): Promise<{ result: AdminUserStatsInterface, error: string }> {
    return this.fetch(this.getStatsPath() + '/stats_for_admin_user_stats', {
      method: 'POST',
      body: this.modifyDateFromAndDateToForBackend(filter),
    });
  }

  /**
   * Sakinių duomenų užklausa
   * Administratoriaus sritis
   * @param {SentenceStatsFilterWithPagination} filter 
   * 
   * @returns Promise<{{SentenceWithClipsData[]} result, {string} error}>
   */
  fetchDataForAdminSentenceStats(
    filter: SentenceStatsFilterWithPagination
  ): Promise<{ result: SentenceWithClipsData[], error: string }> {
    return this.fetch(this.getStatsPath() + '/data_for_admin_sentence_stats', {
      method: 'POST',
      body: this.modifyDateFromAndDateToForBackend(filter),
    });
  }

  /**
   * Sakinių statistikos užklausa
   * Administratoriaus sritis
   * @param {SentenceStatsFilter} filter 
   * 
   * @returns Promise<{{AdminSentenceStatsInterface} result, {string} error}>
   */
  fetchStatsForAdminSentenceStats(
    filter: SentenceStatsFilter
  ): Promise<{ result: AdminSentenceStatsInterface, error: string }> {
    return this.fetch(this.getStatsPath() + '/stats_for_admin_sentence_stats', {
      method: 'POST',
      body: this.modifyDateFromAndDateToForBackend(filter),
    });
  }

  /**
   * Įvertinimų duomenų užklausa
   * Administratoriaus sritis
   * @param {VoteStatsFilterWithPagination} filter 
   * 
   * @returns Promise<{{DBVote[]} result, {string} error}>
   */
  fetchDataForAdminVoteStats(
    filter: VoteStatsFilterWithPagination
  ): Promise<{ result: DBVote[], error: string }> {
    return this.fetch(this.getStatsPath() + '/data_for_admin_vote_stats', {
      method: 'POST',
      body: this.modifyDateFromAndDateToForBackend(filter),
    });
  }

  /**
   * Įvertinimų statistikos užklausa
   * Administratoriaus sritis
   * @param {VoteStatsFilter} filter 
   * 
   * @returns Promise<{{AdminStatsVoteStatsInterface} result, {string} error}>
   */
  fetchStatsForAdminVoteStats(
    filter: VoteStatsFilter
  ): Promise<{ result: AdminStatsVoteStatsInterface, error: string }> {
    return this.fetch(this.getStatsPath() + '/stats_for_admin_vote_stats', {
      method: 'POST',
      body: this.modifyDateFromAndDateToForBackend(filter),
    });
  }

  changeClipCaptchaState = ({id, state }: CaptchaClipsRequest): Promise<{result: boolean, error: string}> => {
    return this.fetch(this.getStatsPath() + '/change_clip_captcha_state', {
      method: 'POST',
      body: {
        id,
        state
      }
    })
  }

  /**
   * Convert date_to and date_from from Date to String YYYY-mm-dd format
   * 
   * @param {{Date} date_from, {Date} date_to, {Date} date_in, {Date} date_out}
   * @returns {{String} date_from, {String} date_to}
   */
  modifyDateFromAndDateToForBackend = (filter: any) => {
    let props = ['date_from', 'date_to', 'date_in', 'date_out'];
    try {
      if (filter) {
        for (const prop of props) {
          if (filter.hasOwnProperty(prop) && filter[prop]) {
            filter[prop] = formatDate(filter[prop].toString())
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
    return filter;
  }

  /**
   * Kategorijų duomenų užklausa.
   * Administratoriaus sritis.
   * 
   * @param {CategoryStatsFilterWithPagination} filter
   * @returns Promise <{CategoryWithSentenceStats[]} result, {string} error> 
   */
  fetchDataForAdminCategoryStats (filter: CategoryStatsFilterWithPagination): Promise<{result:CategoryWithSentenceStats[], error: string}> {
    return this.fetch(this.getStatsPath() + '/data_for_admin_category_stats', {
      method: 'POST',
      body: this.modifyDateFromAndDateToForBackend(filter),
    })
  }

  /**
   * Kategorijų statistikos užklausa
   * Administratoriaus sritis
   * @param {CategoryStatsFilterWithPagination} filter 
   * 
   * @returns Promise<{{AdminCategoryStatsInterface} result, {string} error}>
   */
  fetchStatsForAdminCategoryStats(
    filter: CategoryStatsFilterWithPagination
  ): Promise<{ result: AdminCategoryStatsInterface, error: string }> {
    return this.fetch(this.getStatsPath() + '/stats_for_admin_category_stats', {
      method: 'POST',
      body: this.modifyDateFromAndDateToForBackend(filter),
    });
  }

  /**
   * Užklausa frazių kategorijoms gauti
   * Administratoriaus sritis
   * 
   * @returns  Promise<{{CategoryInterface[]} result, {string} error}>
   */
  fetchAllCategories(): Promise<{
    result: CategoryInterface[],
    error: string
  }> {
    return this.fetch(this.getStatsPath() + '/all_categories');
  }

  /**
   * Užklausa eksportuoti garso įrašus pagal filtrus
   * @param {ClipStatsFilterWithPagination} filter 
   * 
   * @returns Promise<{{string} result, {string} error} >
   */
  exportFilteredData(
    type: 'clips' | 'sentences',
    filter: ClipStatsFilterWithPagination
  ): Promise<{ result: string; error: string }> {
    return this.fetch(this.getStatsPath() + '/export_filtered_data', {
      method: 'POST',
      body: {
        type,
        filter
      },
    });
  }

  /**
   * Užklausa patvirtintiems garso įrašams gauti
   * @param filter 
   * 
   * @returns Promise<{result: {valid_count:number, valid_length:number}, error: string}>
   */
  fetchValidatedHours(filter: ClipStatsFilterWithPagination): Promise<{result: {valid_count:number, valid_length:number}, error: string}> {
    return this.fetch(this.getStatsPath() + '/validated_hours', {
      method: 'POST',
      body: filter,
    });
  }
  /**
   * Užklausa visų diktorių dienos garso įrašų kiekiui gauti
   * Statistikos puslapis
   * 
   * @returns Promise<number>
   */
  fetchDailyClipsCount(): Promise<number> {
    return this.fetch(this.getClipPath() + '/daily_count');
  }

  /**
   * Užklausa visų ekspertų dienos garso įrašų įvertinimų kiekiui gauti
   * Statistikos puslapis
   * 
   * @returns Promise<number>
   */
  fetchDailyVotesCount(): Promise<number> {
    return this.fetch(this.getClipPath() + '/votes/daily_count');
  }

  /**
   * Užklausa konkretaus diktoriaus dienos garso įrašų kiekiui gauti
   * Statistikos puslapis
   * 
   * @returns Promise<number>
   */
  fetchMyDailyClipsCount(): Promise<number> {
    return this.fetch(this.getClipPath() + '/my_daily_count');
  }

  /**
   * Užklausa konkretaus eksperto dienos garso įrašų įvertinimų kiekiui gauti
   * Statistikos puslapis
   * 
   * @returns Promise<number>
   */
  fetchMyDailyVotesCount(): Promise<number> {
    return this.fetch(this.getClipPath() + '/votes/my_daily_count');
  }

  fetchLocaleMessages(): Promise<string> {
    return this.fetch(`/locales/lt/messages.ftl`, {
      isJSON: false,
    });
  }

  async fetchCrossLocaleMessages(): Promise<string[][]> {
    return Object.entries(
      await this.fetch(`/cross-locale-messages.json`)
    ) as string[][];
  }

  fetchRequestedLanguages(): Promise<string[]> {
    return this.fetch(`${API_PATH}/requested_languages`);
  }

  requestLanguage(language: string): Promise<void> {
    return this.fetch(`${API_PATH}/requested_languages`, {
      method: 'POST',
      body: { language },
    });
  }

  async fetchLanguageStats(): Promise<LanguageStats> {
    return this.fetch(`${API_PATH}/language_stats`);
  }

  /**
   * Užklausa privacy ir terms puslapiams gauti
   * @param {'privacy' | 'terms'} name 
   */
  fetchDocument(name: 'privacy' | 'terms' | 'faq' | 'instructions'): Promise<string> {
    return this.fetch(`/${name}/${this.locale}.html`, {
      isJSON: false,
    });
  }

  /**
   * Užklausa sakiniams gauti skip atveju
   * 
   * @param {number} id
   */
  skipSentence(id: number):Promise<{result: boolean, error: string}> {
    return this.fetch(API_PATH + '/skipped_sentences/' + id, {
      method: 'POST',
    });
  }

  /**
   * Užklausa garso įrašo praleidimui išsaugoti
   * 
   * @param {number} id
   */
  skipClip (id: number): Promise<{result: boolean, error: string}> {
    return this.fetch(this.getClipPath() + '/skip_clip', {
      method: 'POST',
      body: {
        id
      }
    })
  }

  /**
   * Užklausa, ar vartotojas gali praleisti garso įrašą
   * 
   */
  canSkipClip (): Promise<{result: boolean, error: string}> {
    return this.fetch(this.getClipPath() + '/can_skip_clip');
  }

  /**
   * Ar vartotojas gali praleisti sakinį?
   *
   */
  canSkipSentence (): Promise<{result: boolean, error: string}> {
    return this.fetch(API_PATH + '/can_skip_sentence');
  }

  fetchClipsStats(
    locale?: string
  ): Promise<{ date: string; total: number; valid: number }[]> {
    return this.fetch(API_PATH + (locale ? '/' + locale : '') + '/clips/stats');
  }

  fetchClipVoices(locale?: string): Promise<{ date: string; value: number }[]> {
    return this.fetch(
      API_PATH + (locale ? '/' + locale : '') + '/clips/voices'
    );
  }

  fetchUserClients(): Promise<UserClient[]> {
    return this.fetch(API_PATH + '/user_clients');
  }

  fetchAccount(): Promise<UserClient> {
    return this.fetch(API_PATH + '/user_client');
  }

  saveAccount(data: UserClient): Promise<UserClient> {
    return this.fetch(API_PATH + '/user_client', {
      method: 'PATCH',
      body: data,
    });
  }

  /**
   * Užklausa išsaugoti admin nustatymams
   * 
   * @param {SettingsInterface} data 
   */
  async saveOtherSettings(data: SettingsInterface): Promise<{ result: boolean; error: string }> {
    return await this.fetch(API_PATH + '/save_other_settings', {
      method: 'POST',
      body: data,
    });
  }


  /**
   * Užklausa ištrinti garso įrašų katalogus, kurių diktorių nėra duomenų bazėje
   * 
   * @returns Promise<{{boolean} result, {string} error}>
   */
  deleteClipFoldersWithNoUsers = async (): Promise<{result: boolean, error: string}> => {
    return await this.fetch(this.getClipPath() + '/delete_clip_folders_with_no_users');
  }


  /**
   * Užklausa gauti garso įrašų katalogams, kurių diktorių nėra duomenų bazėje
   * 
   * @returns Promise<{{string[]} result, {string} error}>
   */
  findClipFoldersWithNoUsers = async (): Promise<{result: string[], error: string}> => {
    return await this.fetch(this.getClipPath() + '/get_clip_folders_with_no_users');
  }

  async getSettings(): Promise<Setting[]> {
    const result = await this.fetch(API_PATH + '/get_settings');
    if (result.error || typeof result.result == 'undefined') {
      return null;
    }
    return result.result;
  }

  /**
   * Užklausa išsaugoti sakinius iš uploadinamo failo
   * 
   * @param file 
   */
  saveSentencesFile(file?: Blob) {
    return this.fetch(API_PATH + '/sentences_import', {
      method: 'POST',
      isJSON: false,
      ...(file ? { body: file } : {}),
    }).then(body => JSON.parse(body));
  }

  /**
   * Užklausa sakinių kategorijoms gauti
   * 
   * @returns Promise<{{Category[]} result, {string} error}>
   */
  async fetchBuckets(): Promise<{ result: Category[]; error: string }> {
    return this.fetch(API_PATH + '/get_buckets_with_sentence_count');
  }

  /**
   * Užklausa sakinių failams serveryje gauti
   * 
   * @returns Promise<{{string[]} result, {string} error}>
   */
  async fetchSentenceFiles(): Promise<{result:string[], error:string}> {
    return this.fetch(API_PATH + '/get_sentence_files');
  }

  /**
   * Užklausa sakinio tekstui išsaugoti
   * 
   * @param {Sentence} sentence 
   * @returns Promise<{{boolean} result, {string} error}>
   */
  async saveSentence(sentence: Sentence): Promise<{ result: boolean; error: string }> {
    return this.fetch(this.getStatsPath() + '/save_sentence_text', {
      method: 'POST',
      body: sentence,
    });
  }

  /**
   * Užklausa sukurtoms užduotims gauti.
   * Administratoriaus sritis.
   * 
   * @returns Promise<{{savedTask[]} result}>
   */
  async fetchTasks(): Promise<{result: savedTask[]}> {
    return this.fetch(API_PATH + '/get_tasks_with_buckets');
  }

   /**
   * Užklausa mano užduotims gauti.
   * vartotojo profilio puslapis
   * 
   * @returns Promise<{{TaskWithExecution[]} result}>
   */
  async getMyTasks(): Promise<{result: TaskWithExecution[], error:string}> {
    return this.fetch(API_PATH + '/user_client/get_my_tasks');
  }

  /**
   * Užklausa vartotojo registracijos patvirtinimui
   * 
   * @returns Promise<{{string} message, {string} error}>
   */
  async resendEmail(): Promise<{result: string, error: string}> {
    return this.fetch(API_PATH + '/user_client/resend_email');
  }

  /**
   * Užklausa informacijai apie pakartotinį registracijos patvirtinimo laiško išsiuntimą
   * 
   * @returns Promise<{boolean}>
   */
  async activationEmailWasResent(): Promise<boolean> {
    return this.fetch(API_PATH + '/user_client/email_has_been_resent');
  }

  /**
   * Užklausa diktavimo variantams gauti.
   * Administratoriaus sritis.
   * 
   * @promise visi sukurti diktavimo variantai
   * @resolve {RecordVariant[]} result
   * @resolve {string} error
   */
  async fetchRecordingVariants(): Promise<{result: RecordVariant[], error: string}> {
    return this.fetch(API_PATH + '/get_record_variants');
  }

  /**
   * Užklausa diktavimo variantui išsaugoti.
   * Administratoriaus sritis.
   * 
   * @param {RecordVariant} data 
   * @returns Promise<{{boolean} result?}>
   */
  async insertRecordVariant(data: RecordVariant): Promise<{result?: boolean}> {
    return this.fetch(API_PATH + '/insert_record_variant', {
      method: 'POST',
      body: data,
    });
  }

  /**
   * Užklausa užduočiai ištrinti.
   * Administratoriaus sritis.
   * 
   * @param taskId 
   * @returns
   */
  async deleteTask(taskId: number) {
    return this.fetch(API_PATH + '/delete_task', {
      method: 'POST',
      body: {
        taskId,
      },
    });
  }

  /**
   * Užklausa garso įrašui ištrinti.
   * Administratoriaus sroitis.
   * 
   * @param {number} clipId - garso įrašo id
   * @returns Promise<{{boolean} result, {string} error}>
   */
  async deleteClip(
    clipId: number
  ): Promise<{ result: boolean; error: string }> {
    return this.fetch(API_PATH + '/delete_clip', {
      method: 'POST',
      body: {
        clipId,
      },
    });
  }

  /**
   * Užklausa diktorių duomenims gauti.
   * Administratoriaus sritis.
   * 
   * @param filter 
   * @resolve {{UserWithContribution[]} result, {string} error}
   */
  async fetchUsers(
    filter: UsersFilterWithPaginationInterface
  ): Promise<{ result: UserWithContribution[]; error: string }> {
    return this.fetch(API_PATH + '/get_users', {
      method: 'POST',
      body: this.modifyDateFromAndDateToForBackend(filter),
    });
  }

  async fetchUser(userId: number): Promise<{result: BaseUser, error: string}> {
    return this.fetch(API_PATH + '/get_user', {
      body: {userId},
      method: 'POST',
    });
  }

  /**
   * Užklausa diktorių statistikai gauti.
   * Administratoriaus sritis.
   * 
   * @param {UsersFilterWithPaginationInterface} filter 
   * @resolve {{AdminUserStatsInterface} result, {string} error} 
   */
  async fetchUsersStats(
    filter: UsersFilterWithPaginationInterface
  ): Promise<{ result: AdminUserStatsInterface; error: string }> {
    return this.fetch(API_PATH + '/get_users_stats', {
      method: 'POST',
      body: this.modifyDateFromAndDateToForBackend(filter),
    });
  }

  /**
   * Užklausa ištrinti vartotoją
   * 
   * @param {BaseUser} user 
   */
  async deleteUser(user: BaseUser) {
    return this.fetch(API_PATH + '/delete-user', {
      method: 'POST',
      body: user,
    });
  }

  /**
   * Užklausa ištrinti diktoriaus garso įrašus
   * 
   * @param {BaseUser} user 
   */
  async deleteUserClips(user: BaseUser) {
    return this.fetch(API_PATH + '/delete-user-clips', {
      method: 'POST',
      body: user,
    });
  }

  /**
   * Užklausa ištrinti eksperto garso įrašų vertinimus
   * 
   * @param {BaseUser} user 
   */
  async deleteExpertVotes(user: BaseUser) {
    return this.fetch(API_PATH + '/delete-expert-votes', {
      method: 'POST',
      body: user,
    });
  }

  /**
   * Užklausa nustatyti vartotojo slaptažodį standartiniu
   * 
   * @param {BaseUser} user 
   */
  async resetUserPassword(user: BaseUser) {
    return this.fetch(API_PATH + '/reset-user-password', {
      method: 'POST',
      body: user,
    });
  }

  /**
   * Gaunama partnerystės programos informacija
   * 
   * @returns Promise<{{AffiliateInfo} result, {string} error}>
   */
  getAffiliateInfo = async (): Promise<{result: AffiliateInfoInterface, error: string}> => {
    return this.fetch(API_PATH + '/get_affiliate_info');
  }

  /**
   * Gaunama nuosavo indėlio informacija
   * 
   * @returns Promise<{{number} points}>
   */
  getMyContributionInfo = async (): Promise<{result: ContributionPointsTabbleInterface, error: string}> => {
    return this.fetch(API_PATH + '/get_my_contribution_info');
  }

  /**
   * Užklausa pakeisti vartotojo parametrus
   * 
   * @param {BaseUser} user 
   * @returns Promise<{result: BaseUser, error: string}>
   */
  updateUser = async (user: BaseUser): Promise<{result:BaseUser, error:string}> => {
    return this.fetch(API_PATH + '/update_user', {
      method: 'POST',
      body: user,
    });
  }

  /**
   * Užklausa suspenduoti vartotoją
   *
   * @param {BaseUser} user
   * @returns Promise<{result: BaseUser, error: string}>
   */
  userSuspendToggle = async (user: BaseUser): Promise<{result:boolean, error:string}> => {
    return this.fetch(API_PATH + '/user_suspend_toggle', {
      method: 'POST',
      body: user,
    });
  };

  /**
   * Gauti informaciją apie vartotojo suspendavimą
   * @param user
   * @returns UserSuspendInterface
   */
  fetchUserSuspend = async (): Promise<{result: UserSuspendInterface, error: string}> => {
    return this.fetch(API_PATH + '/fetch_user_suspend');
  };

  fetchLeaderboard(type: 'clip' | 'vote', cursor?: [number, number]) {
    return this.fetch(
      this.getClipPath() +
        (type == 'clip' ? '' : '/votes') +
        '/leaderboard' +
        (cursor ? '?cursor=' + JSON.stringify(cursor) : '')
    );
  }

  claimAccount(): Promise<void> {
    return this.fetch(
      API_PATH + '/user_clients/' + this.user.userId + '/claim',
      { method: 'POST' }
    );
  }
}
