import * as React from 'react';
import { LabeledInput, LinkButton, TextButton } from '../ui/ui';
import {
  LocalizationProps,
  Localized,
  withLocalization,
} from 'fluent-react/compat';
import Modal from '../modal/modal';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import StateTree from '../../stores/tree';
import { User } from '../../stores/user';
import Cookies from 'universal-cookie';

interface PropsFromState {
  user: User.State;
}

interface Props
  extends LocalizationProps,
    PropsFromState,
    RouteComponentProps<any> {}

interface LoginState {
  email: string;
  password: string;
  affiliate: string;
  agreeTerms: boolean;
  showLoginPopup: boolean;
  showRegisterPopup: boolean;
}

class Login extends React.Component<Props, LoginState> {
  constructor(props: Props) {
    super(props);
    
    this.state = {
      email: '',
      password: '',
      affiliate: null,
      showLoginPopup: false,
      showRegisterPopup: false,
      agreeTerms: false,
    };
  }

  componentDidMount = () => {
    try {
      const cookies = new Cookies();
      const ac = cookies.get('affiliate_code');
      if (ac) {
        this.setState({
          affiliate: ac,
        })
      }
    } catch(e) {
      console.error(e.message);
    }
  }

  getFormUrl = (): string => {
    return this.state.showLoginPopup ? '/login' : '/register';
  };

  validateForm = (): boolean => {
    return this.state.email.length > 0 && this.state.password.length > 0;
  };

  handleChangeFor = (field: keyof LoginState) => ({
    target,
  }: React.ChangeEvent<any>) => {
    this.setState({
      [field]: target.type == 'checkbox' ? target.checked : target.value,
    } as any);
  };

  hideModal = () => {
    this.setState({
      showLoginPopup: false,
      showRegisterPopup: false,
    });
  };

  showModal = () => {
    if (this.state.affiliate) {
      this.showRegister();
    } else {
      this.showLogin();
    }
  }

  showLogin = () => {
    this.setState({
      showLoginPopup: true,
      showRegisterPopup: false,
    });
  };

  showRegister = () => {
    this.setState({
      showLoginPopup: false,
      showRegisterPopup: true,
    });
  };

  render() {
    const { email, password, showLoginPopup, showRegisterPopup, affiliate, agreeTerms } = this.state;
    const { user } = this.props;
    user.account && this.hideModal(); //jei vartotojas prisijunges, negalim rodyti prisijungimo lango

    return (
      <div>
        <Localized id="login-signup">
          <LinkButton onClick={this.showModal} className="login" rounded />
        </Localized>
        {(showLoginPopup || showRegisterPopup) && (
          <Modal innerClassName="contact-modal" onRequestClose={this.hideModal}>
            {showRegisterPopup && (
              <Localized id="login-text">
                <TextButton onClick={this.showLogin} />
              </Localized>
            )}
            {showLoginPopup && (
              <Localized id="register-text">
                <TextButton onClick={this.showRegister} />
              </Localized>
            )}
            <form
              action={this.getFormUrl()}
              method="post"
              encType="application/x-www-form-urlencoded">
              <div className="title-and-action">
                <Localized
                  id={showRegisterPopup ? 'register-text' : 'login-text'}>
                  <h1 />
                </Localized>
              </div>

              <br />

              <Localized id="email-input" attrs={{ label: true }}>
                <LabeledInput
                  label="Email"
                  name="email"
                  required
                  type="email"
                  value={email}
                  onChange={this.handleChangeFor('email')}
                />
              </Localized>

              <Localized id="login-form-password" attrs={{ label: true }}>
                <LabeledInput
                  label="Password"
                  name="password"
                  required
                  type="password"
                  value={password}
                  onChange={this.handleChangeFor('password')}
                />
              </Localized>

              {showRegisterPopup && <Localized id="register-form-affiliate" attrs={{ label: true }}>
                <LabeledInput
                  label="Affiliate"
                  name="affiliate"
                  type="text"
                  value={affiliate || ''}
                  onChange={this.handleChangeFor('affiliate')}
                />
              </Localized>}

              {showRegisterPopup && <div id="agreeTerms">
              <input
                type="checkbox"
                name="agreeTerms"
                checked={agreeTerms || false}
                onChange={this.handleChangeFor('agreeTerms')}
              />
              <Localized
                  id="agree-terms"
                  termsLink={<a href="/terms" /> }>
                  <span />
              </Localized>
              <br /><br />
              </div>}
              
              <div className="actions">
                <Localized
                  className="login-reg-btn"
                  id={showRegisterPopup ? 'register-text' : 'login-text'}>
                  <TextButton 
                    type="submit" 
                    disabled={showRegisterPopup && !agreeTerms}  
                  />
                </Localized>
                <div />
              </div>
            </form>
          </Modal>
        )}
      </div>
    );
  }
}

export default connect<PropsFromState>(({ user }: StateTree) => ({
  user,
}))(withLocalization(withRouter(Login)));
