import { Localized } from 'fluent-react/compat';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { trackGlobal } from '../../services/tracker';
import StateTree from '../../stores/tree';
import { User } from '../../stores/user';
import { Locale } from '../../stores/locale';
import URLS from '../../urls';
import { LocaleLink, LocaleNavLink } from '../locale-helpers';
import {
  CogIcon,
  DashboardIcon,
  MenuIcon,
  MicIcon,
  OldPlayIcon,
  PlayOutlineIcon,
} from '../ui/icons';
import { LinkButton } from '../ui/ui';
import Content from './content';
import Footer from './footer';
import Logo from './logo';
import Nav from './nav';
import UserMenu from './user-menu';
import Login from './login';
import API from '../../services/api';
import Cookies from 'universal-cookie';
import CookieBanner from 'react-cookie-banner';

interface PropsFromState {
  locale: Locale.State;
  user: User.State;
  api: API;
}

interface PropsFromDispatch {
  setLocale: typeof Locale.actions.set;
}

interface LayoutProps
  extends PropsFromState,
    PropsFromDispatch,
    RouteComponentProps<any> {}

interface LayoutState {
  isMenuVisible: boolean;
  hasScrolled: boolean;
  hasScrolledDown: boolean;
  showStagingBanner: boolean;
}

class Layout extends React.PureComponent<LayoutProps, LayoutState> {
  private header: HTMLElement;
  private scroller: HTMLElement;

  COOKIE_NAME_AGREED_COOKIES = 'agreed_cookies';
  COOKIE_NAME_AFFILIATE_CODE = 'affiliate_code';

  state: LayoutState = {
    isMenuVisible: false,
    hasScrolled: false,
    hasScrolledDown: false,
    showStagingBanner: true,
  };

  /**
   * Komponento konstruktorius
   * 
   * @param {LayoutProps} props 
   */
  constructor(props: LayoutProps) {
    super(props);
    try {
      const { location, user } = props;
      const cookies = new Cookies();
      
      if (user.account) {
        cookies.remove(this.COOKIE_NAME_AFFILIATE_CODE)
      } else {
        const search = location.search.split('=');
        const affiliateLink = search.length == 2 && search[1].length == 5 ? search[1] : null;
        
        if (affiliateLink && cookies.get(this.COOKIE_NAME_AGREED_COOKIES)) {
          cookies.set(this.COOKIE_NAME_AFFILIATE_CODE, affiliateLink, { path: '/' });
        }
      }
    } catch (e) {
      console.error(e.toString());
    }
  }

  componentDidMount() {
    this.scroller.addEventListener('scroll', this.handleScroll);
    setTimeout(() => {
      import('../pages/contribution/speak/speak');
      import('../pages/contribution/listen/listen');
    }, 1000);
    this.visitHash(this.props);
  }

  componentDidUpdate(nextProps: LayoutProps, nextState: LayoutState) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.setState({ isMenuVisible: false });

      // Immediately scrolling up after page change has no effect.
      setTimeout(() => {
        this.scroller.scrollTop = 0;
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        this.visitHash(nextProps);
      }, 250);
    }
  }

  componentWillUnmount() {
    this.scroller.removeEventListener('scroll', this.handleScroll);
  }

  private visitHash({ location: { hash } }: LayoutProps) {
    if (hash) {
      const node = document.querySelector(hash);
      node && node.scrollIntoView();
    }
  }

  lastScrollTop: number;
  private handleScroll = () => {
    const { scrollTop } = this.scroller;
    this.setState({
      hasScrolled: scrollTop > 0,
      hasScrolledDown: scrollTop > this.lastScrollTop,
    });
    this.lastScrollTop = scrollTop;
  };

  private toggleMenu = () => {
    this.setState({ isMenuVisible: !this.state.isMenuVisible });
  };

  render() {
    const { location, user } = this.props;
    const {
      hasScrolled,
      hasScrolledDown,
      isMenuVisible,
    } = this.state;
    const isBuildingProfile = location.pathname.includes(URLS.PROFILE_INFO);

    const pathParts = location.pathname.split('/');
    const className = pathParts[2] ? pathParts.slice(2).join(' ') : 'home';

    return (
      <div id="main" className={className}>
        <CookieBanner
          onAccept={() => {
            if(String(window.location).indexOf('?inv=') !== -1) {
              window.location.reload();
            }
          }}
          cookie={this.COOKIE_NAME_AGREED_COOKIES} 
          message="Šioje svetainėje naudojame slapukus (angl. „cookies“). Jie padeda atpažinti prisijungusius vartotojus, bei keisti svetainę, kad ji būtų jums patogesnė. Plačiau apie tai: "
          link={<a style={{color:'#ffff', fontWeight: 'bold'}} href='/faq'>DUK</a>}
          buttonMessage='Sutinku'
          dismissOnScroll= {false}
          styles={{
            banner: { 
              backgroundColor: 'rgba(60, 60, 60, 0.8)',
              height: 'auto',
              position: 'fixed'
            },
            message: { fontWeight: 400 },
            button: {
              position: 'relative',
              float: 'right',
              right: '0px',
              margin: '10px',
              background: 'white',
              color: 'black'
            }
          }}
        />
        <header
          className={
            !isMenuVisible &&
            (hasScrolled ? 'active' : '')
          }
          ref={header => {
            this.header = header as HTMLElement;
          }}>
          <div>
            <Logo />
            <Nav id="main-nav" user={user} />
          </div>
          <div>
            {this.renderTallies()}
            {user.account ? <UserMenu /> : isBuildingProfile ? null : <Login />}
            <button
              id="hamburger-menu"
              onClick={this.toggleMenu}
              className={isMenuVisible ? 'active' : ''}>
              <MenuIcon className={isMenuVisible ? 'active' : ''} />
            </button>
          </div>
        </header>
        <div
          id="scroller"
          ref={div => {
            this.scroller = div as HTMLElement;
          }}>
          <div id="scrollee">
            <Content />
            <Footer />
          </div>
        </div>
        <div
          id="navigation-modal"
          className={this.state.isMenuVisible ? 'active' : ''}>
          <Nav>
            <div className="user-nav">
              {user.account && (
                <div>
                  <LocaleNavLink className="user-nav-link" to={URLS.SPEAK}>
                    <MicIcon />
                    <Localized id="speak">
                      <span />
                    </Localized>
                  </LocaleNavLink>
                  <LocaleNavLink className="user-nav-link" to={URLS.LISTEN}>
                    <PlayOutlineIcon />
                    <Localized id="listen">
                      <span />
                    </Localized>
                  </LocaleNavLink>
                  <LocaleNavLink className="user-nav-link" to={URLS.DASHBOARD}>
                    <DashboardIcon />
                    <Localized id="dashboard">
                      <span />
                    </Localized>
                  </LocaleNavLink>
                  <LocaleNavLink
                    className="user-nav-link"
                    to={URLS.PROFILE_INFO}>
                    <CogIcon />
                    <Localized id="profile">
                      <span />
                    </Localized>
                  </LocaleNavLink>
                </div>
              )}
              {!isBuildingProfile && (
                <React.Fragment>
                  {user.account ? 
                    <Localized id="logout">
                      <LinkButton rounded href="/logout" />
                    </Localized> 
                    : 
                    <Login />
                  }
                </React.Fragment>
              )}
            </div>
          </Nav>
        </div>
      </div>
    );
  }

  private renderTallies() {
    const { user } = this.props;
    return (
      <LocaleLink
        className="tallies"
        to={user.account ? URLS.DASHBOARD : URLS.SPEAK}>
        <div className="record-tally">
          <MicIcon />
          <div>
            {user.account ? user.account.clips_count : user.recordTally}
          </div>
        </div>
        <div className="divider" />
        <div className="validate-tally">
          <OldPlayIcon />
          {user.account ? user.account.votes_count : user.validateTally}
        </div>
      </LocaleLink>
    );
  }
}

const mapStateToProps = (state: StateTree) => ({
  locale: state.locale,
  user: state.user,
  api: state.api,
});

const mapDispatchToProps = {
  setLocale: Locale.actions.set,
};

export default withRouter(
  connect<PropsFromState, PropsFromDispatch>(
    mapStateToProps,
    mapDispatchToProps
  )(Layout)
);
