import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import URLS from '../../urls';
import { Notifications } from '../../stores/notifications';
import StateTree from '../../stores/tree';
import { User } from '../../stores/user';
import {
  LocalizationProps,
  withLocalization,
} from 'fluent-react/compat';

interface PropsFromState {
  user: User.State;
}

interface PropsFromDispatch {
  addNotification: typeof Notifications.actions.add;
}

interface Props extends PropsFromDispatch, LocalizationProps, RouteComponentProps, PropsFromState {}

export const LoginFailure = connect<PropsFromState, PropsFromDispatch>(
    ({ user }: StateTree) => ({user}),
    { addNotification: Notifications.actions.add }
  )(withRouter(withLocalization(
    class extends React.Component<Props> {
      componentDidMount() {
        const { addNotification, history } = this.props;
        addNotification('Prisijungimas nepavyko', 'error');
        history.replace(URLS.ROOT);
      }

      render(): React.ReactNode {
        return null;
      }
    }
  ))
);

export const RegistrationFailure = connect<PropsFromState, PropsFromDispatch>(
    ({ user }: StateTree) => ({user}),
    { addNotification: Notifications.actions.add }
  )(withRouter(withLocalization(
    class extends React.Component<Props> {
      componentDidMount() {
        const { addNotification, history } = this.props;
        addNotification('Registracija nepavyko', 'error');
        history.replace(URLS.ROOT);
      }

      render(): React.ReactNode {
        return null;
      }
    }
  ))
);

export const SentencesImportStarted = connect<PropsFromState, PropsFromDispatch>(
  ({ user }: StateTree) => ({user}),
  { addNotification: Notifications.actions.add }
)(withRouter(withLocalization(
    class extends React.Component<Props> {
      componentDidMount() {
        const { addNotification, history } = this.props;
        addNotification('Sakinių importas pradėtas. Loguose stebėkite eigą.', 'success');
        history.replace(URLS.SENTENCES_IMPORT);
      }

      render(): React.ReactNode {
        return null;
      }
    }
  ))
);

export const UserMsg = connect<PropsFromState, PropsFromDispatch>(
  ({ user }: StateTree) => ({user}),
  { addNotification: Notifications.actions.add },
)(withRouter(withLocalization(
    class extends React.Component<Props> {
      componentDidMount() {
        const { addNotification, history, getString, match } = this.props;
        //@ts-ignore
        addNotification(getString(match.params.msg), match.params.type);
        history.replace(URLS.ROOT);
      }

      render(): React.ReactNode {
        return null;
      }
    }
  ))
);

export const LoginSuccess = connect<PropsFromState, PropsFromDispatch>(
  ({ user }: StateTree) => ({user}),
  { addNotification: Notifications.actions.add }
)(withRouter(withLocalization(
  class extends React.Component<Props> {
    componentDidMount() {
      this.redirect(this.props);
    }
  
    componentWillReceiveProps(props: Props) {
      this.redirect(props);
    }
  
    redirect({ history, user }: Props) {
      const { account, isFetchingAccount } = user;
      if (isFetchingAccount) return;
      history.replace(account ? URLS.ROOT : URLS.PROFILE_INFO);
    }
  
    render(): React.ReactNode {
      return null;
    }
  }
)));