export type UserPermissionType = 'user' | 'expert' | 'admin'

export const USER_PERMISSION_USER = 'user' as UserPermissionType;
export const USER_PERMISSION_EXPERT = 'expert' as UserPermissionType;
export const USER_PERMISSION_ADMIN = 'admin' as UserPermissionType; 

export interface UserClient {
  email?: string;
  hash?: string;
  client_id?: string;
  age?: string;
  gender?: string;
  record_variant_message?: string;
  visible?: 0 | 1;
  clips_count?: number;
  votes_count?: number;
  permissions?: UserPermissionType;
  username?: string;
  suspended?: 0 | 1;
  email_confirmed: 0 | 1;
}

export interface BaseUser {
  id: number;
  email: string;
  username: string;
  permissions: UserPermissionType;
  hq_audio: 0 | 1;
  parent_id?: number;
  invitation_code?: string;
  suspended: 0 | 1;
  password? : string;
  vote_weight: number;
  login_token: string;
  email_confirmed: 0 | 1;
  created_at?: string;
}

export interface UserWithClipCount extends BaseUser {
  clips_count: number;
  votes_count?: number;
}

export interface UserWithDetailedVotesStats extends UserWithClipCount {
  upvotes: number;
  downvotes: number;
  voted_clips_count: number;
  votes_stat: number;
}

export interface UserWithContribution extends UserWithDetailedVotesStats {
  affiliate_count: number;
  contribution_points: number;
}