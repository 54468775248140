import {
  Localized,
  withLocalization,
  LocalizationProps,
} from 'fluent-react/compat';
import * as React from 'react';
import Modal from '../modal/modal';
import { Button, LabeledInput, LabeledTextArea } from '../ui/ui';
import { Notifications } from '../../stores/notifications';
import API from '../../services/api';
import { User } from '../../stores/user';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import StateTree from '../../stores/tree';

interface PropsFromState {
  api: API;
  user: User.State;
}

interface PropsFromDispatch {
  addNotification: typeof Notifications.actions.add;
}

interface Props
  extends PropsFromState,
    PropsFromDispatch,
    LocalizationProps,
    RouteComponentProps {
  onRequestClose: () => void;
}

interface State {
  email: string;
  name: string;
  message: string;
  sending: boolean;
  subject: string;
}
/**
 * Modulis atsakingas už laiško teksto perdavimą į backend
 */
class ContactModal extends React.Component<Props, State> {
  state = {
    email: null,
    name: null,
    message: null,
    sending: false,
    subject: null
  };

  /**
   * laiškas perduodamas į backend
   * @see API.sendEmail
   */
  sendMessage = e => {
    e.preventDefault();
    const { addNotification, getString, onRequestClose, api } = this.props;
    if (!this.state.sending) {
      this.setState(
        {
          sending: true,
        },
        () => {
          api.sendEmail(this.state).then(({ result, error }) => {
            if (error) {
              addNotification(getString(error), 'error');
            } else if (result) {
              addNotification(getString('message-sent'));
            } else {
              addNotification(getString('error'), 'error');
            }
          });
          this.setState(
            {
              sending: false,
            },
            () => {
              onRequestClose();
            }
          );
        }
      );
    }
  };

  /**
   * jei vartotojas prisijungęs, užsetinam jo kontaktinius duomenis į būseną
   */
  componentDidMount() {
    const { user } = this.props;
    if (user && user.account) {
      const { email, username } = user.account;
      this.setState({
        email,
        name: username,
      });
    }
  }

  handleChangeFor = ({ target }: React.ChangeEvent<any>) => {
    this.setState({
      [target.name]:
        target.type == 'checkbox' ? target.checked : target.value || null,
    } as any);
  };

  render() {
    const { email, name, message } = this.state;
    const { onRequestClose } = this.props;

    return (
      <Modal innerClassName="contact-modal" onRequestClose={onRequestClose}>
        <form onSubmit={this.sendMessage}>
          <div className="title-and-action">
            <Localized id="contact-title">
              <h1 />
            </Localized>
          </div>

          <br />

          <Localized id="email-input" attrs={{ label: true }}>
            <LabeledInput
              label="Email"
              name="email"
              value={email || ''}
              onChange={this.handleChangeFor}
              required
              type="email"
            />
          </Localized>

          <Localized id="contact-form-name" attrs={{ label: true }}>
            <LabeledInput
              label="Name"
              name="name"
              value={name || ''}
              onChange={this.handleChangeFor}
              type="text"
            />
          </Localized>

          <Localized id="contact-form-message" attrs={{ label: true }}>
            <LabeledTextArea
              label="Message"
              name="message"
              value={message || ''}
              onChange={this.handleChangeFor}
              required
              rows={6}
            />
          </Localized>

          <div className="actions">
            <Localized id="contact-required">
              <div />
            </Localized>
            <Localized id="submit-form-action">
              <Button type="submit" />
            </Localized>
            <div />
          </div>
        </form>
      </Modal>
    );
  }
}

export default connect<PropsFromState, PropsFromDispatch>(
  ({ api, user }: StateTree) => ({
    api,
    user,
  }),
  {
    addNotification: Notifications.actions.add,
  }
)(withRouter(withLocalization(ContactModal)));
