import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import URLS from '../../urls';
import {
  localeConnector,
  LocalePropsFromState,
} from '../locale-helpers';
import DocumentPage from '../pages/document-page';
import NotFoundPage from '../pages/not-found';
import { Spinner } from '../ui/ui';
const HomePage = React.lazy(() => import('../pages/home/home'));
const DataPage = React.lazy(() => import('../pages/data/data'));
const DashboardPage = React.lazy(() => import('../pages/dashboard/dashboard'));
const ProfileLayoutPage = React.lazy(() => import('../pages/profile/layout'));
const ClipStatsPage = React.lazy(() => import('../pages/admin-stats/clip-stats/clip-stats'));
const ExpertStatsPage = React.lazy(() => import('../pages/admin-stats/expert-stats/expert-stats'));
const VoteStatsPage = React.lazy(() => import('../pages/admin-stats/vote-stats/vote-stats'));
const SentenceStatsPage = React.lazy(() => import('../pages/admin-stats/sentence-stats/sentence-stats'));
const UserStatsPage = React.lazy(() => import('../pages/admin-stats/user-stats/user-stats'));
const UserPage = React.lazy(() => import('../pages/profile/users/users'));
const SentencesImportPage = React.lazy(() => import('../pages/profile/sentences-import/sentences-import'));
const TasksPage = React.lazy(() => import('../pages/profile/tasks/tasks'));
const OtherSettingsPage = React.lazy(() => import('../pages/profile/other/other'));
const UserSuspendPage = React.lazy(() => import('../pages/admin-stats/user-suspend/user-suspend'));
const CategoryStatsPage = React.lazy(() => import('../pages/admin-stats/category-stats/category-stats'));

export default localeConnector(
  ({ locale, toLocaleRoute }: LocalePropsFromState) => (
    <div id="content">
      <React.Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path={toLocaleRoute(URLS.ROOT)} component={HomePage} />
          <Route
            exact
            path={toLocaleRoute('/new')}
            render={() => <Redirect to={toLocaleRoute(URLS.ROOT)} />}
          />
          <Route
            exact
            path={toLocaleRoute(URLS.RECORD)}
            render={() => (
              <Redirect
                to={toLocaleRoute(URLS.SPEAK)}
              />
            )}
          />
          <Route
            exact
            path={toLocaleRoute(URLS.DATA)}
            render={() => <Redirect to={toLocaleRoute(URLS.DATASETS)} />}
          />
          <Route
            exact
            path={toLocaleRoute(URLS.DATASETS)}
            component={DataPage}
          />
          <Route
            exact
            path={toLocaleRoute(URLS.PROFILE)}
            render={() => <Redirect to={toLocaleRoute(URLS.PROFILE_INFO)} />}
          />
          <Route
            path={toLocaleRoute(URLS.PROFILE + '/')}
            component={ProfileLayoutPage}
          />
          <Route
            path={toLocaleRoute(URLS.DASHBOARD)}
            component={DashboardPage}
          />
          <Route
            exact
            path={toLocaleRoute(URLS.CLIPS_STATS)}
            component={ClipStatsPage}
          />
          <Route
            exact
            path={toLocaleRoute(URLS.EXPERT_STATS)}
            component={ExpertStatsPage}
          />
          <Route
            exact
            path={toLocaleRoute(URLS.VOTE_STATS)}
            component={VoteStatsPage}
          />
          <Route
            exact
            path={toLocaleRoute(URLS.USER_STATS)}
            component={UserStatsPage}
          />
          <Route
            exact
            path={toLocaleRoute(URLS.USERS)}
            component={UserPage}
          />
          <Route
            exact
            path={toLocaleRoute(URLS.SENTENCES_IMPORT)}
            component={SentencesImportPage}
          />
          <Route
            exact
            path={toLocaleRoute(URLS.TASK_CREATE)}
            component={TasksPage}
          />
          <Route
            exact
            path={toLocaleRoute(URLS.OTHER_SETTINGS_PAGE)}
            component={OtherSettingsPage}
          />
          <Route
            exact
            path={toLocaleRoute(URLS.USER_SUSPEND_PAGE)}
            component={UserSuspendPage}
          />
          <Route
            exact
            path={toLocaleRoute(URLS.SENTENCE_STATS)}
            component={SentenceStatsPage}
          />
          <Route
            exact
            path={toLocaleRoute(URLS.CATEGORY_STATS)}
            component={CategoryStatsPage}
          />
          <Route
            exact
            path={toLocaleRoute(URLS.FAQ)}
            render={() => <DocumentPage key="f" name="faq" />}
          />
          <Route
            exact
            path={toLocaleRoute(URLS.PRIVACY)}
            render={() => <DocumentPage key="p" name="privacy" />}
          />
          />
          <Route
            exact
            path={toLocaleRoute(URLS.INSTRUCTIONS)}
            render={() => <DocumentPage key="p" name="instructions" />}
          />
          <Route
            exact
            path={toLocaleRoute(URLS.TERMS)}
            render={() => <DocumentPage key="t" name="terms" />}
          />
          <Route component={NotFoundPage} />
        </Switch>
      </React.Suspense>
    </div>
  )
);
